import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {
  UserLoginInfo,
  InterviewBuilderRules,
  AccountInfoService,
  AlertsService,
  CustomRuleService,
  SessionInfoService,
  SessionKey,
  RuleCode,
} from 'degarmo-lib';

@Injectable({ providedIn: 'root' })
export class InterviewBuilderRulesResolver {
  constructor(
    private accountInfoService: AccountInfoService,
    private alertsService: AlertsService,
    private customRuleService: CustomRuleService,
    private sessionInfoService: SessionInfoService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<InterviewBuilderRules> {
    return this.loadRules();
  }

  loadRules = (): Observable<InterviewBuilderRules> => {
    const userLoginInfo: UserLoginInfo = this.accountInfoService.getUserLoginInfo();
    return new Observable((subscriber) => {
      let companyID: number = this.sessionInfoService.getConstant(SessionKey.InterviewBuilderCompanyID, 'number', true);

      if (userLoginInfo.isProctor) {
        if (companyID !== userLoginInfo.companyID) {
          companyID = userLoginInfo.companyID;
          this.sessionInfoService.setConstant(SessionKey.InterviewBuilderCompanyID, companyID, true);
        }
      }

      if (companyID) {
        this.alertsService.showLoading(false, 1);

        this.customRuleService
          .getRules({
            sets: ['InterviewBuilder', 'InterviewContentManagement', 'InterviewGuide'],
            companyID: companyID,
            proctorID: userLoginInfo.isProctor ? userLoginInfo.proctorID : 0,
          })
          .subscribe({
            next: (rules) => {
              const priorities: string[] = ['proctorID', 'companyID'];
              const retVal: InterviewBuilderRules = {
                canAddInterviewTags:
                  this.customRuleService.getPrioritizedRuleValue(RuleCode.CanAddInterviewTemplateTags, priorities, '0', rules) === '1' ? true : false,
                canCreateTechnicalQuestions:
                  this.customRuleService.getPrioritizedRuleValue(RuleCode.CanCreateTechnicalQuestions, priorities, '0', rules) === '1' ? true : false,
                canSetInterviewGuidesAsPrivate:
                  this.customRuleService.getPrioritizedRuleValue(RuleCode.CanSetInterviewGuidesAsPrivate, priorities, '0', rules) === '1'
                    ? true
                    : false,
                dimensionsGroupedByMacro:
                  this.customRuleService.getPrioritizedRuleValue(RuleCode.DimensionsGroupedByMacro, priorities, '0', rules) === '1' ? true : false,
                displayAssessmentCategory:
                  this.customRuleService.getPrioritizedRuleValue(RuleCode.DisplayAssessmentCategories, priorities, '0', rules) === '1' ? true : false,
                displayJobLevel:
                  this.customRuleService.getPrioritizedRuleValue(RuleCode.DisplayJobLevel, priorities, '0', rules) === '1' ? true : false,
                filterByJobLevelID:
                  this.customRuleService.getPrioritizedRuleValue(RuleCode.JobLevelFilterToggle, priorities, '0', rules) === '1' ? true : false,
                maxLabelsAllowed: Number(this.customRuleService.getPrioritizedRuleValue(RuleCode.MaxLabelsAllowed, priorities, '0', rules)),
                minLabelsAllowed: Number(this.customRuleService.getPrioritizedRuleValue(RuleCode.MinLabelsAllowed, priorities, '0', rules)),
                maxQuestions: Number(this.customRuleService.getPrioritizedRuleValue(RuleCode.MaxQuestions, priorities, '0', rules)),
                minQuestions: Number(this.customRuleService.getPrioritizedRuleValue(RuleCode.MinQuestions, priorities, '0', rules)),
                maxTechQuestions: Number(this.customRuleService.getPrioritizedRuleValue(RuleCode.MaxTechQuestions, priorities, '0', rules)),
                minTechQuestions: Number(this.customRuleService.getPrioritizedRuleValue(RuleCode.MinTechQuestions, priorities, '0', rules)),
                showJobCode: this.customRuleService.getPrioritizedRuleValue(RuleCode.ShowJobCode, priorities, '0', rules) === '1' ? true : false,
                showRequisitionNumber:
                  this.customRuleService.getPrioritizedRuleValue(RuleCode.ShowRequisitionNumber, priorities, '0', rules) === '1' ? true : false,
                useDigitalGuides:
                  this.customRuleService.getPrioritizedRuleValue(RuleCode.UseDigitalGuides, priorities, '0', rules) === '1' ? true : false,
                requisitionNumberValidationRequirements: this.customRuleService.getPrioritizedRuleValue(
                  RuleCode.RequisitionNumberValidationRequirements,
                  priorities,
                  '',
                  rules
                ),
                jobCodeValidationRequirements: this.customRuleService.getPrioritizedRuleValue(
                  RuleCode.JobCodeValidationRequirements,
                  priorities,
                  '',
                  rules
                ),
                questionNumberingStyle: this.customRuleService.getPrioritizedRuleValue(RuleCode.QuestionNumberingStyle, priorities, 'reset', rules),
                interviewQuestionType: this.customRuleService.getPrioritizedRuleValue(
                  RuleCode.InterviewQuestionType,
                  priorities,
                  'Behavioral',
                  rules
                ),
              };

              this.alertsService.doneLoading();
              this.sessionInfoService.setConstant(SessionKey.InterviewBuilderRules, retVal, true);
              subscriber.next(retVal);
            },
            error: (err) => {
              this.alertsService.doneLoading();
              subscriber.error(err);
            },
            complete: () => {
              subscriber.complete();
            },
          });
      } else {
        subscriber.next(null);
        subscriber.complete();
      }
    });
  };
}
